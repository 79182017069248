<template>
  <div class="food-detail-page w-100">
    <b-form @submit="submit">
      <basic-subheader
        :title="
          form_type === 'create' ? 'Thêm thức ăn mới' : 'Chỉnh sửa thức ăn'
        "
      >
        <template v-slot:actions>
          <b-button
            class="btn btn-light ml-3"
            type="button"
            pill
            @click="returnPage"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
            </span>
            Trở về trước
          </b-button>
          <b-button class="btn btn-warning ml-3" type="button" pill>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/refresh-cw.svg" />
            </span>
            Reset dữ liệu
          </b-button>
          <template v-if="form_type === 'create'">
            <b-button class="btn btn-primary ml-3" type="submit" pill>
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
              </span>
              Tạo mới
            </b-button>
          </template>
          <template v-else>
            <b-button class="btn btn-danger ml-3" type="button" pill>
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/trash-2.svg" />
              </span>
              Xóa món ăn
            </b-button>
            <b-button class="btn btn-primary ml-3" type="button" pill>
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/check.svg" />
              </span>
              Cập nhật
            </b-button>
          </template>
        </template>
      </basic-subheader>
      <b-container fluid class="food-detail-page__body mb-6 mt-6">
        <div class="card card-custom">
          <b-row class="border-bottom">
            <b-col class="col-6 col-lg-4 col-xl-3">
              <h6 class="d-flex align-items-center p-6 mb-0 text-primary">
                <span class="svg-icon mr-3">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/info.svg" />
                </span>
                Thông Tin Món Ăn
              </h6>
            </b-col>
          </b-row>
          <b-row align-h="center" class="p-6">
            <b-col cols="8">
              <b-row>
                <b-col>
                  <basic-input
                    label="Tên món ăn:"
                    placeholder="Nhập tên của món ăn"
                    name="name"
                    :required="true"
                    :value.sync="form.name"
                    :state="validationState.name"
                    :invalidFeedback="error.name"
                  ></basic-input>
                </b-col>

                <b-col>
                  <basic-select
                    label="Nhóm món ăn:"
                    placeholder="Chọn nhóm món ăn"
                    name="foodCategory"
                    apiPath="/foodCategory"
                    :required="true"
                    :value.sync="form.foodCategory"
                    :state="validationState.foodCategory"
                    :invalidFeedback="error.foodCategory"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-input
                    label="Khẩu phần:"
                    placeholder="Nhập khẩu phần của món ăn"
                    name="portion"
                    inputType="number"
                    :required="true"
                    :value.sync="form.portion"
                    :state="validationState.portion"
                    :invalidFeedback="error.portion"
                  ></basic-input>
                </b-col>

                <b-col>
                  <basic-input
                    label="Lượng calo:"
                    placeholder="Nhập lượng calo của món ăn"
                    name="calorie"
                    inputType="number"
                    :required="true"
                    :value.sync="form.calorie"
                    :state="validationState.calorie"
                    :invalidFeedback="error.calorie"
                  ></basic-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <basic-input
                    label="Lượng đường:"
                    placeholder="Nhập lượng đường của món ăn"
                    name="glucose"
                    inputType="number"
                    :required="true"
                    :value.sync="form.glucose"
                    :state="validationState.glucose"
                    :invalidFeedback="error.glucose"
                  ></basic-input>
                </b-col>

                <b-col>
                  <basic-input
                    label="Chất béo:"
                    placeholder="Nhập lượng béo của món ăn"
                    name="lipid"
                    inputType="number"
                    :required="true"
                    :value.sync="form.lipid"
                    :state="validationState.lipid"
                    :invalidFeedback="error.lipid"
                  ></basic-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <basic-input
                    label="Chất đạm:"
                    placeholder="Nhập lượng đạm của món ăn"
                    name="protein"
                    inputType="number"
                    :required="true"
                    :value.sync="form.protein"
                    :state="validationState.protein"
                    :invalidFeedback="error.protein"
                  ></basic-input>
                </b-col>

                <b-col>
                  <basic-input
                    label="Chất xơ:"
                    placeholder="Nhập lượng xơ của món ăn"
                    name="fibre"
                    inputType="number"
                    :required="true"
                    :value.sync="form.fibre"
                    :state="validationState.fibre"
                    :invalidFeedback="error.fibre"
                  ></basic-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    form_type: {
      type: String,
      default: 'detail',
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: null,
        portion: null,
        calorie: null,
        glucose: null,
        lipid: null,
        protein: null,
        fibre: null,
        foodCategory: null,
      },
      validationState: {
        name: null,
        portion: null,
        calorie: null,
        glucose: null,
        lipid: null,
        protein: null,
        fibre: null,
        foodCategory: null,
      },
      error: {
        name: null,
        portion: null,
        calorie: null,
        glucose: null,
        lipid: null,
        protein: null,
        fibre: null,
        foodCategory: null,
      },
    };
  },

  computed: {
    foodData: {
      get() {
        let newFood = {
          name: this.form.name,
          portion: this.form.portion,
          calorie: this.form.glucose,
          glucose: this.form.glucose,
          lipid: this.form.lipid,
          protein: this.form.protein,
          fibre: this.form.fibre,
          foodCategory: this.form.foodCategory,
        };
        return newFood;
      },
      set(data) {
        this.form.name = data.name;
        this.form.portion = data.portion;
        this.form.calorie = data.calorie;
        this.form.glucose = data.glucose;
        this.form.lipid = data.lipid;
        this.form.protein = data.protein;
        this.form.fibre = data.fibre;
        this.form.foodCategory = data.foodCategory;
      },
    },
  },
  methods: {
    returnPage() {
      this.$router.push({
        name: 'food_list',
      });
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
      );
    },
    nameValidation() {
      let nameState = null;
      let nameValidation = null;
      if (!this.form.name || !this.form.name.trim().length) {
        nameState = false;
        nameValidation = 'Không được bỏ trống tên';
      } else {
        nameState = true;
        nameValidation = null;
      }
      this.validationState.name = nameState;
      this.error.name = nameValidation;
    },
    portionValidation() {
      let portionState = null;
      let portionValidation = null;
      if (!this.form.portion || !this.form.portion.trim().length) {
        portionState = false;
        portionValidation = 'Không được bỏ trống tên';
      } else {
        portionState = true;
        portionValidation = null;
      }
      this.validationState.portion = portionState;
      this.error.portion = portionValidation;
    },
    foodCategoryValidation() {
      let foodCategoryState = null;
      let foodCategoryValidation = null;
      if (!this.form.foodCategory) {
        foodCategoryState = false;
        foodCategoryValidation = 'Không được bỏ trống nhóm món ăn';
      } else {
        foodCategoryState = true;
        foodCategoryValidation = null;
      }
      this.validationState.foodCategory = foodCategoryState;
      this.error.foodCategory = foodCategoryValidation;
    },
    isFormValid() {
      let isValid = true;
      for (let field of Object.keys(this.form)) {
        let validationFunction = `${field}Validation`;
        if (
          this[validationFunction] &&
          typeof this[validationFunction] === 'function'
        ) {
          this[validationFunction]();
        }
      }

      for (let validate of Object.keys(this.validationState)) {
        if (this.validationState[validate] === false) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    create() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .post('food', {
          ...this.foodData,
          id: this.uuidv4(),
          createdDate: new Date().toUTCString(),
        })
        .then(() => {
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Thêm món ăn thành công',
          });
        })
        .catch((error) => {
          if (error) {
            // if (error.code === 'duplicated_code') {
            //   this.validationState.code = false;
            //   this.error.code = `Mã Nhân Viên không được trùng ${this.form.code}`;
            //   this.$toastr.e({
            //     title: 'Lỗi !',
            //     msg: 'Mã Nhân Viên không được trùng',
            //   });
            // }
          }
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
    },
    edit() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .put(`food/${this.id}`, this.foodData)
        .then(() => {
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Cập nhật món ăn thành công',
          });
        })
        .catch((error) => {
          if (error) {
            // if (error.code === 'duplicated_code') {
            //   this.validationState.code = false;
            //   this.error.code = `Đã có Chi nhánh với Mã ${this.form.code}`;
            //   this.$toastr.e({
            //     title: 'Lỗi!',
            //     msg: 'Mã Chi nhánh không được trùng',
            //   });
            // }
          }
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
    },
    submit($event) {
      $event.preventDefault();
      let isFormValid = this.isFormValid();
      if (isFormValid) {
        if (isFormValid) {
          if (this.form_type === 'create') {
            this.create();
          } else if (this.form_type === 'edit') {
            this.edit();
          }
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
      return;
    },
    async loadExerciseData() {
      await this.$api.get(`branch/${this.id}`).then(({ data }) => {
        this.foodData = JSON.parse(JSON.stringify(data));
      });
    },
  },
  async mounted() {
    this.$store.commit('context/setLoading', true);
    if (this.id) {
      await this.loadExerciseData();
    }
    this.$store.commit('context/setLoading', false);
  },
  // beforeMount() {},
  // beforeRouteLeave(to, from, next) {},
};
</script>
